// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "TwoColumnBanner-module--aboutMainCircleBlurBlue--0d7f6";
export var bHeading = "TwoColumnBanner-module--bHeading--7cd8a";
export var development = "TwoColumnBanner-module--development--a1e7c";
export var gdnPromotePic = "TwoColumnBanner-module--gdnPromotePic--34302";
export var head = "TwoColumnBanner-module--head--d4018";
export var heroBackgroundCircleLeft = "TwoColumnBanner-module--heroBackgroundCircleLeft--2b247";
export var heroBackgroundCircleLeftStory = "TwoColumnBanner-module--heroBackgroundCircleLeftStory--7e9c5";
export var heroBackgroundCircleLeftStorySecond = "TwoColumnBanner-module--heroBackgroundCircleLeftStorySecond--1ad5a";
export var heroBackgroundInnerCircleLeft = "TwoColumnBanner-module--heroBackgroundInnerCircleLeft--53413";
export var heroBackgroundInnerCircleLeftSecond = "TwoColumnBanner-module--heroBackgroundInnerCircleLeftSecond--56fdf";
export var knowUs = "TwoColumnBanner-module--knowUs--11dab";
export var knowUsHeading = "TwoColumnBanner-module--knowUsHeading--c48f7";
export var knowUsImage = "TwoColumnBanner-module--knowUsImage--c146c";
export var knowUsImageDiv = "TwoColumnBanner-module--knowUsImageDiv--72b12";
export var knowUsOurStory = "TwoColumnBanner-module--knowUsOurStory--89938";
export var knowUsOurStoryText = "TwoColumnBanner-module--knowUsOurStoryText--71b65";
export var knowUsText = "TwoColumnBanner-module--knowUsText--eba78";
export var ourStoryDescPara = "TwoColumnBanner-module--ourStoryDescPara--35c49";
export var ourStoryImg = "TwoColumnBanner-module--ourStoryImg--3d7bc";
export var ourStoryImgDiv = "TwoColumnBanner-module--ourStoryImgDiv--7e63e";
export var ourStoryText = "TwoColumnBanner-module--ourStoryText--ef4b9";
export var storyPromotePic = "TwoColumnBanner-module--storyPromotePic--95b95";
export var storyPromotePicSecond = "TwoColumnBanner-module--storyPromotePicSecond--6feaf";
export var topHeading = "TwoColumnBanner-module--topHeading--d42f3";